import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/linkbutton"
import { StaticImage } from "gatsby-plugin-image"
import "../stylesheets/index.scss"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="" />
      <div className="homePage-MainVisual">
        <div className="homePage-MainVisual-bg" />
        <div className="homePage-MainVisual-inner">
          <h1 className="homePage-MainVisual-title">
            <StaticImage
              src="../images/company_logo.png"
              alt="DoshinInteractive"
              className="homePage-MainVisual-titleImage"
              placeholder=""
            />
          </h1>
          <p className="homePage-MainVisual-subTitle">
            地域から信頼される
            <br />
            北海道最大の人材プラットフォームを構築する。
          </p>
        </div>
      </div>
      <PhilosophySection />
      <CompanySection />
      <ServiceSection />
      <RecruitSection />
      <NewsSection data={data} />
      <ContactSection />
    </Layout>
  )
}

const CompanySection = () => {
  const headingRef = React.useRef(null)
  const paragraphRef = React.useRef(null)
  const imageRef = React.useRef(null)
  const buttonRef = React.useRef(null)

  React.useEffect(() => {
    let observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.setAttribute("data-fadein", "true")
          }
        })
      },
      {
        threshold: 0,
      }
    )

    observer.observe(headingRef.current)
    observer.observe(paragraphRef.current)
    observer.observe(imageRef.current)
    observer.observe(buttonRef.current)
  }, [])

  return (
    <section className="homePage-CompanySection">
      <div className="homePage-CompanySection-imageArea" ref={imageRef}>
        <StaticImage
          src="../images/company_section_image.jpg"
          alt=""
          className="homePage-CompanySection-image"
        />
      </div>
      <div className="homePage-CompanySection-textArea">
        <h2 className="homePage-CompanySection-heading" ref={headingRef}>
          会社情報
        </h2>
        <p className="homePage-CompanySection-text" ref={paragraphRef}>
          地域から信頼される
          <br />
          北海道最大の人材プラットフォームを構築する。
        </p>
        <div
          className="homePage-PhilosophySection-buttonWrapper"
          ref={buttonRef}
        >
          <Button to="/company" className="Button">
            詳しく見る
          </Button>
        </div>
      </div>
    </section>
  )
}

const PhilosophySection = () => {
  const headingRef = React.useRef(null)
  const paragraphRef = React.useRef(null)
  const imageRef = React.useRef(null)
  const buttonRef = React.useRef(null)

  React.useEffect(() => {
    let observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.setAttribute("data-fadein", "true")
          }
        })
      },
      {
        threshold: 0,
      }
    )

    observer.observe(headingRef.current)
    observer.observe(paragraphRef.current)
    observer.observe(imageRef.current)
    observer.observe(buttonRef.current)
  }, [])

  return (
    <section className="homePage-PhilosophySection">
      <div className="homePage-PhilosophySection-textArea">
        <h2 className="homePage-PhilosophySection-heading" ref={headingRef}>
          企業理念
        </h2>
        <p className="homePage-PhilosophySection-text" ref={paragraphRef}>
          人と企業を結ぶ懸け橋となり、
          <br />
          地域の発展に貢献する。
        </p>
        <div
          className="homePage-PhilosophySection-buttonWrapper"
          ref={buttonRef}
        >
          <Button to="/philosophy" className="Button">
            詳しく見る
          </Button>
        </div>
      </div>
      <div className="homePage-PhilosophySection-imageArea" ref={imageRef}>
        <StaticImage
          src="../images/philosophy_section_image.jpg"
          alt=""
          className="homePage-PhilosophySection-image"
        />
      </div>
    </section>
  )
}

const ServiceSection = () => {
  const headingRef = React.useRef(null)
  const cardRef = React.useRef(null)

  React.useEffect(() => {
    let observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.setAttribute("data-fadein", "true")
          }
        })
      },
      {
        threshold: 0.5,
      }
    )

    observer.observe(headingRef.current)
    observer.observe(cardRef.current)
  }, [])

  return (
    <section className="homePage-ServiceSection">
      <div className="homePage-ServiceSection-inner">
        <h2 className="homePage-ServiceSection-heading" ref={headingRef}>
          事業内容
        </h2>
        <div className="homePage-ServiceSection-card" ref={cardRef}>
          <div className="homePage-ServiceSection-cardTextArea">
            <StaticImage
              src="../images/jobantenna.png"
              alt="Job Antenna"
              className="homePage-ServiceSection-cardImage"
            />
            <h3 className="homePage-ServiceSection-cardTitle">
              いいかも！で企業とつながる。
              <br />
              北海道に特化した求人マッチングサービス
            </h3>
          </div>
          <div className="homePage-ServiceSection-cardLinkArea">
            <div className="homePage-ServiceSection-cardLinkWrapper">
              <Button to="/business" className="Button">
                詳しく見る
              </Button>
            </div>
            {/* <div className="homePage-ServiceSection-cardLinkWrapper">
              <Button to="https://www.jobantenna.jp/" target="_blank" className="Button--primary Button--openInNew-primary">サイトを見る</Button>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}

const RecruitSection = () => {
  // const headingRef = React.useRef(null)
  // const cardRef = React.useRef(null)

  // React.useEffect(() => {
  //   let observer = new IntersectionObserver(
  //     entries => {
  //       entries.forEach(entry => {
  //         if (entry.isIntersecting) {
  //           entry.target.setAttribute("data-fadein", "true")
  //         }
  //       })
  //     },
  //     {
  //       threshold: 0.5,
  //     }
  //   )

  //   // observer.observe(headingRef.current)
  //   // observer.observe(cardRef.current)
  // }, [])

  return (
    <section className="homePage-RecruitSection">
      <div className="homePage-RecruitSection-inner">
        <div className="homePage-RecruitSection-wrapper">
          <div className="homePage-RecruitSection-imageWrapper">
            <StaticImage src="../images/recruit.jpg" alt="Job Antenna" />
          </div>
          <div>
            <h2 className="homePage-RecruitSection-title">
              採用情報
            </h2>
            <p className="homePage-RecruitSection-text">
              スタートアップ企業の創業メンバーとして、
              <br />
              一緒に会社を盛り上げてくれる方をお待ちしています。
            </p>
            <div className="homePage-RecruitSection-buttonWrapper">
              <Button
                to="https://hokkaido.jobantenna.jp/at/15400/offer/"
                target="_blank"
                rel="noreferrer"
                className="Button Button--openInNew"
              >
                ジョブアンテナ北海道に
                <br />
                求人情報掲載中
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const NewsSection = ({ data }) => {
  const headingRef = React.useRef(null)
  const listRef = React.useRef(null)
  const buttonRef = React.useRef(null)

  React.useEffect(() => {
    let observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.setAttribute("data-fadein", "true")
          }
        })
      },
      {
        threshold: 0.5,
      }
    )

    observer.observe(headingRef.current)
    observer.observe(listRef.current)
    observer.observe(buttonRef.current)
  }, [])

  return (
    <section className="homePage-NewsSection">
      <h2 className="homePage-NewsSection-heading" ref={headingRef}>
        お知らせ
      </h2>
      <div className="homePage-NewsSection-inner">
        <ul className="homePage-NewsSection-list" ref={listRef}>
          {data.allMicrocmsNews.edges.map(({ node }) => (
            <li key={node.newsId} className="homePage-NewsSection-listItem">
              <Link
                to={`/news/${node.newsId}`}
                className="homePage-NewsSection-listLink"
              >
                <p className="homePage-NewsSection-listDate">
                  {node.created_at}
                </p>
                <p className="homePage-NewsSection-listTitle">{node.title}</p>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="homePage-NewsSection-buttonWrapper" ref={buttonRef}>
        <Button to="/news" className="Button">
          詳しく見る
        </Button>
      </div>
    </section>
  )
}

// const RecruitSection = () => {
//   const cardRef = React.useRef(null)
//   const cardHeadingRef = React.useRef(null)
//   const cardTextRef = React.useRef(null)

//   React.useEffect(() => {
//     let observer = new IntersectionObserver(
//       entries => {
//         entries.forEach(entry => {
//           if (entry.isIntersecting) {
//             entry.target.setAttribute("data-fadein", "true")
//           }
//         })
//       },
//       {
//         threshold: 0.5,
//       }
//     )

//     observer.observe(cardRef.current)
//     observer.observe(cardHeadingRef.current)
//     observer.observe(cardTextRef.current)
//   }, [])

//   return (
//     <section className="homePage-RecruitSection">
//       <div className="homePage-RecruitSection-inner">
//         <div className="homePage-RecruitSection-card" ref={cardRef}>
//           <h2
//             className="homePage-RecruitSection-cardTitle"
//             ref={cardHeadingRef}
//           >
//             採用情報
//           </h2>
//           <div className="homePage-RecruitSection-cardTextArea">
//             <p className="homePage-RecruitSection-cardText" ref={cardTextRef}>
//               北海道の求人市場の
//               <br />
//               未来を変える！
//             </p>
//             <p className="homePage-RecruitSection-cardSubText">
//               スタートアップ企業の創業メンバーとして、一緒に会社を盛り上げてくれる方を
//               <br />
//               お待ちしています。
//             </p>
//             <div className="homePage-RecruitSection-buttonWrapper">
//               <Button
//                 to="https://www.jobantenna.jp/at/294763/offer/"
//                 target="_blank"
//                 className="Button--secondary Button--openInNew-secondary"
//               >
//                 ジョブアンテナ北海道に
//                 <br />
//                 求人情報掲載中
//               </Button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }

const ContactSection = () => {
  const cardRef = React.useRef(null)

  React.useEffect(() => {
    let observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.setAttribute("data-fadein", "true")
          }
        })
      },
      {
        threshold: 0.5,
      }
    )

    observer.observe(cardRef.current)
  }, [])

  return (
    <section className="homePage-ContactSection">
      <div className="homePage-ContactSection-inner">
        <div className="homePage-ContactSection-card" ref={cardRef}>
          <div className="homePage-ContactSection-cardInner">
            <h2 className="homePage-ContactSection-heading">お問い合わせ</h2>
            <p className="homePage-ContactSection-cardText">
              弊社へのご質問は、こちらからお問い合わせください。
            </p>
            <div className="homePage-ContactSection-buttonWrapper">
              <Button to="/contact" className="Button">
                フォームへ
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allMicrocmsNews(limit: 5) {
      edges {
        node {
          id
          created_at(formatString: "YYYY/MM/DD")
          newsId
          title
          contents
        }
      }
    }
  }
`
